import React, { useState, useReducer, useEffect } from "react"
import { Modal } from "react-bootstrap"
import { navigate } from "gatsby-link"
import { Link } from "gatsby"
import { RiCloseLine } from "react-icons/ri";

import SelectField from "./common/form/SelectField"
import {
  conditionJson,
  wantingJson,
  yesNoOption,
  amountJson,
  reasonJson,
  timeJson,
  HOME_SCREEN,
} from "../constant"
import ButtonField from "./common/form/ButtonField"
import { setStorage, getStorage } from "../utils"
import request from "../services/request"

const initialState = {
  currently_listed: "",
  require_repairs: "",
  current_condition: "",
  urgency: "",
  asking_price: "",
  reason_to_sell: "",
  time_to_call: "",
}

const reducer = (state, action) => {
  switch (action.type) {
    case action.type:
      return { ...state, [action.type]: action.value }
    default:
      throw new Error()
  }
}

const Thanks = () => {
  const [modalShow, setModalShow] = useState(false)
  const [state, dispatch] = useReducer(reducer, initialState)

  useEffect(() => {
    if (getStorage("formData")) {
      const {
        currently_listed,
        require_repairs,
        current_condition,
        urgency,
        asking_price,
        reason_to_sell,
        time_to_call,
      } = getStorage("formData")
      let obj = {
        currently_listed,
        require_repairs,
        current_condition,
        urgency,
        asking_price,
        reason_to_sell,
        time_to_call,
      }
      Object.keys(obj).map(keyName =>
        dispatch({
          type: keyName,
          value: obj[keyName] === null ? initialState[keyName] : obj[keyName],
        })
      );

      let initalValue = getStorage("formData")
      window.dataLayer = window.dataLayer || []
      window.dataLayer.push({
        event: "new_lead_submit",
        dtL_new_lead_submit: {
          email: initalValue.email,
          branch: process.env.GATSBY_BRANCH
        },
      });
    }
  }, [])

  const onSubmit = e => {
    e.preventDefault()
    let initialVal = getStorage("formData")
    let finalVal = { ...initialVal, ...state };
    let data = {
      email: finalVal.email,
      clientId: window.ga.getAll()[0].get("clientId"),
      address: finalVal.address,
      first_name: finalVal.first_name,
      last_name: finalVal.last_name,
      ...state,
    }
    setModalShow(true)
    request({ url: "b5vndv0", data })
      .then(() => {
        console.log("Thanks web-hook");
        request({ url: "ov5uppx", data })
        .then(() => {
          window.dataLayer = window.dataLayer || [];          
          window.dataLayer.push({
            event: "extra_details_submit",
            dtL_extra_details_submit: {
              email: data.email,
              asking_price: data.asking_price,
              currently_listed: data.currently_listed,
              require_repairs: data.require_repairs,
              current_condition: data.current_condition,
              urgency: data.urgency,
              time_to_call: data.time_to_call,
              reason_to_sell: data.reason_to_sell,
              branch: process.env.GATSBY_BRANCH
            },
          })
          setModalShow(true)
          setStorage("formData", finalVal)
        })
        .catch(err => {
          console.log("Error", err)
        })
      })
      .catch(err => {
        console.log("Error", err)
      })    
  }
  const onValueChange = (e, type) => {
    dispatch({ type, value: e.target.value })
  }

  const onClose = () => {
    setModalShow(false)
    navigate(HOME_SCREEN)
  }

  const MyVerticallyCenteredModal = props => {
    return (
      <Modal
        {...props}
        size="sm"
        aria-labelledby="contained-modal-title-vcenter p-5"
        centered
        onHide={() => onClose()}
        className="thanksModel"
      >
        <div
          style={{ height: 300 }}
          className="thanks-card d-flex justify-content-center align-items-center flex-column px-3 text-center text-white"
        >
          <h1>Thank You!</h1>
          <h5>Your form has been submitted.</h5>
        </div>
        <Link
          to={HOME_SCREEN}
          className="close-btn position-absolute d-flex align-items-center justify-content-center"
          onClick={e => onClose(e)}
        >
          <RiCloseLine size={25} color="#7f7f7f" />
          {/* <button type="button" className="close" aria-label="Close">
            <span aria-hidden="true" className="text-white">
              &times;
            </span>
          </button> */}
        </Link>
      </Modal>
    )
  }
  return (
    <div className="formBackground">
      <img alt="thanks" src={`//ad.ipredictive.com/d/track/cvt/pixel?acct_id=57868&cache_buster=${Math.floor(Date.now() / 1000)}`} style={{ display:'none' }}width="1" height="1" ></img>
      <form
        className="px-3 text-white thanksContainer"
        onSubmit={e => onSubmit(e)}
      >
        <h1>Thank You!</h1>
        <div className="sub-header mt-2">
          <span className="font-weight-bold">We'll contact you </span>as soon as
          possible with your<span className="font-weight-bold"> cash offer</span>
        </div>
        <div className="row mt-4">
          <div className="col-sm-6 col-12 col-md-6 pr-sm-2">
            <SelectField
              label={"Please select your asking price"}
              id="amound"
              options={amountJson}
              className="list-select"
              value={state.asking_price}
              onChange={e => onValueChange(e, "asking_price")}
            />
          </div>
          <div className="col-sm-6 col-12 col-md-6 pl-sm-2">
            <SelectField
              label={"Is your house currently listed with a realtor?"}
              id="listed"
              options={yesNoOption}
              className="list-select"
              value={state.currently_listed}
              onChange={e => onValueChange(e, "currently_listed")}
            />
          </div>
          <div className="col-sm-6 col-12 col-md-6 pr-sm-2">
            <SelectField
              label={"Does your home currently require repairs?"}
              id="repairs"
              options={yesNoOption}
              className="list-select"
              value={state.require_repairs}
              onChange={e => onValueChange(e, "require_repairs")}
            />
          </div>
          <div className="col-sm-6 col-12 col-md-6 pl-sm-2">
            <SelectField
              label={"What is the current condition of your home?"}
              id="condition"
              options={conditionJson}
              className="list-select"
              value={state.current_condition}
              onChange={e => onValueChange(e, "current_condition")}
            />
          </div>
          <div className="col-sm-6 col-12 col-md-6 pr-sm-2">
            <SelectField
              label={"How fast are you wanting to sell?"}
              id="wanting"
              options={wantingJson}
              className="list-select"
              value={state.urgency}
              onChange={e => onValueChange(e, "urgency")}
            />
          </div>
          <div className="col-sm-6 col-12 col-md-6 pl-sm-2">
            <SelectField
              label={"Reason you want to sell?"}
              id="reason_to_sell"
              options={reasonJson}
              className="list-select"
              value={state.reason_to_sell}
              onChange={e => onValueChange(e, "reason_to_sell")}
            />
          </div>
          <div className="col-sm-6 col-12 col-md-6 pr-sm-2">
            <SelectField
              label={"When is the best time to contact you?"}
              id="timetocontact"
              options={timeJson}
              className="list-select"
              value={state.time_to_call}
              onChange={e => onValueChange(e, "time_to_call")}
            />
          </div>
        </div>
        <MyVerticallyCenteredModal
          show={modalShow}
          onHide={() => setModalShow(false)}
        />
        <ButtonField
          className="btn-primary getOff font-weight-bold w-100"
          type="submit"
          name="Submit"
        />
        <div className="bottom-label mt-2 font-italic">
          *We'll only use this information to get your offer, and it won't be
          shared with anyone else.
        </div>
      </form>
    </div>
  )
}

export default Thanks
